<template>
    <div class="banner-mod">
            <img src="/static/images/auth-banner.png" />
        </div>
    <div class="auth-center-layout">
        <div class="auth-center">            
                <div class="cate-item" @click="toPersonalAuth">
                    <img src="/static/images/img-auth-cate01.png" alt="">
                    <div class="title">个人认证</div>
                    <span>ikeyhub创作达人</span>
                </div>
            
            <div class="cate-item" @click="toEnterpriseAuth">
                <img src="/static/images/img-auth-cate02.png" alt="">
                <div class="title">企业认证</div>
                <span>ikeyhub企业官方账号</span>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name:'index',
    components: {},
    data() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    toPersonalAuth(){
        this.$router.push({
            name:'personal_auth_unmet'
        })
    },
    toEnterpriseAuth(){
        this.$router.push({
            name:'enterprise_auth'
        })
    }
    
  }
}
</script>
<style lang="scss" scoped>
.auth-center-layout{padding:60px 0;background:#fafafa;}
.auth-center{width:1440px;margin:0 auto; display:flex;align-items:center; justify-content: space-between;
    .cate-item{width:700px;height:414px;border-radius: 24px;background: #FFFFFF;display: flex;flex-direction: column;align-items: center;justify-content: center;font-family: Source Han Sans;color: #333333;cursor:pointer;
        img{ display:block;width: 150px;height: 150px;margin-bottom:20px;}
        .title{height: 28px;line-height: 28px;text-align: center;font-family: Source Han Sans;font-size: 28px;font-weight: bold;margin-bottom:10px;}
        span{font-size: 22px;}
    }
}
.banner-mod{position:relative;width:100%;height:300px;
    img{display: block;width:1920px;position: absolute;top:0;left:50%;margin-left:-960px;}
}
</style>